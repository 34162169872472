<template>
  <b-container>
    <b-row>
      
      <b-col>
        <b-button
          class="hide-print mt-3 ml-2 float-right"
          size="sm"
          @click.prevent="viewSanction"
          variant="warning"
          v-if="
            ApplicationStatus == 15 ||
            ApplicationStatus == 34 ||
            ApplicationStatus == 35 ||
            ApplicationStatus == 30
          "
          >Verification Letters</b-button
        >
        <b-button
          class="hide-print mt-3 ml-2 float-right"
          size="sm"
          @click.prevent="printBform"
          variant="success"
          v-if="ApplicationStatus == 20 || ApplicationStatus == 21"
          >B-Form Receipt</b-button
        >
        <b-button
          class="hide-print mt-3 ml-2 float-right"
          size="sm"
          variant="info"
          @click="print"
          >Print Application</b-button
        >
        <b-button
          class="hide-print mt-3 ml-2 float-right"
          @click.prevent="goback"
          variant="secondary"
          size="sm"
          >BACK</b-button
        >
        <b-button
          class="hide-print mt-3 ml-2 float-right"
          @click.prevent="unlock"
          variant="danger"
          size="sm"
            v-if="(RoleID !== 2) && (
            ApplicationStatus <= 3 ||
            ApplicationStatus == 5 ||
            ApplicationStatus == 23 ||
            ApplicationStatus == 27 ||
            ApplicationStatus == 4 ||
            ApplicationStatus == 6 ||
            ApplicationStatus == 7
          )"  
          >Unlock Profile
          <b-spinner
            small
            type="grow"
            variant="warning"
            v-if="isLoadingUnlock"
          ></b-spinner>
        </b-button>
      </b-col>
    </b-row>
     <loading :active.sync="isLoading" 
        color="#5dbbda"
        loader="spinner"
        :is-full-page="fullPage"
        width:2
        height:2
        ></loading>
    <b-alert
      :show="showDismissibleAlertUnlock"
      dismissible
      @dismissed="showDismissibleAlertUnlock = false"
      :variant="variantUnlock"
      >{{ alertTextUnlock }}
    </b-alert>

    <div id="printme">
      <!-- <img
        style="width: 140px; float: left; margin-top: 40px"
        src="@/assets/images/logo.jpg"
      />
      <b-row>
        <b-col>
          <h5 style="margin-top: 30px" align="center">
            <b>Security Guards Board</b>
          </h5>
          <P style="margin-bottom: 70px" align="center"
            ><b>Online Registration</b></P
          >
        </b-col>
      </b-row>
      <hr /> -->
      <!-- <table>
      <h6 class="reg-form-title text-primary">Application Details</h6>
        <tr>
         <td>job-pool</td>
         <td>{{ personalDetails.EventTitle }}</td>
         <td>job-pool</td>
         <td>{{ personalDetails.EventTitle }}</td>
        </tr>
      </table> -->
       
      <div class="application-view">
        <!-- Application Number and Type -->
        <div class="reg-form-box">
          <h5 class="reg-form-title text-primary">Application Details</h5>
          <div class="reg-form-body">
            <div class="row">
              <div class="col-md-2"><strong>Job-Pool</strong></div>
              <div class="col-md-8">
                {{ personalDetails.EventTitle }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><strong>Board Name</strong></div>
              <div class="col-md-8">{{ personalDetails.BoardName }}</div>
            </div>
            <div class="row" v-if="personalDetails.BForm">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4">
                    <strong>Registration Number</strong>
                  </div>
                  <div class="col-md-8">
                    {{ personalDetails.BForm[0].RegistrationNo }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4"><strong>Registration Date</strong></div>
                  <div class="col-md-8">
                    {{ personalDetails.BForm[0].RegistrationDate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <strong>Application No.</strong>
              </div>
              <div class="col-md-4">
                {{ personalDetails.ApplicationNo }}
              </div>
              <div class="col-md-2"><strong>Application Date</strong></div>
              <div class="col-md-4">
                {{ personalDetails.ApplicationDate }}
              </div>
              <!-- <div class="col-md-2">
                <strong>Docket No.</strong>
              </div>
              <div class="col-md-4">
                {{ Docket_no(personalDetails.ApplicationNo) }}
              </div> -->
            </div>
          </div>
        </div>
        <!-- <hr /> -->

        <!-- Personal Details -->
        <div class="reg-form-box">
          <h5 class="reg-form-title text-primary">Personal Details</h5>
          <div class="reg-form-body">
            <!-- <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-4">Registration Type</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserDetail[0].RegistrationType }}
                  </div>
                </div>
              </div>
            </div> -->
            <div class="row" v-if=" personalDetails.UserLogin">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-4">Profile ID :</div>
                  <div class="col-md-8">
                    {{ personalDetails.ProfileNumber }}
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-md-4">Full Name :</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserLogin[0].Fullname }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Gender :</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserDetail[0].Gender }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Birth Date :</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserDetail[0].DOB }}
                  </div>
                  <div class="col-md-4" v-if="personalDetails.BForm">
                    Retirement Date :
                  </div>
                  <div class="col-md-3" v-if="personalDetails.BForm">
                    {{ personalDetails.BForm[0].RetirementDate }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Place of Birth :</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserDetail[0].PlaceOfBirth }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Email Id</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserLogin[0].EmailID }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Mobile No:</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserLogin[0].Mobile }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Landline No:</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserDetail[0].LandlineNo }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">PanCard No</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserDetail[0].PanCardNo }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Adhar Card No</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserDetail[0].AdharNo }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Mother Name</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserDetail[0].MotherName }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Married Status</div>
                  <div
                    class="col-md-8"
                    v-if="personalDetails.UserDetail[0].MaritalStatus"
                  >
                    Yes
                  </div>
                  <div class="col-md-8" v-else>No</div>
                </div>
                <div class="row">
                  <div class="col-md-4">Nationality</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserDetail[0].Nationality }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Maharashtra Domiciled</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserDetail[0].Domiciled }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Ex-Serviceman</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserDetail[0].ExServiceMan }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Driving</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserDetail[0].Driving }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Mother Toungue</div>
                  <div class="col-md-8">
                    {{ personalDetails.UserDetail[0].MotherToung }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">Languages Known</div>
                  <div class="col-md-8">
                    Marathi: {{ personalDetails.UserDetail[0].Marathi }}, Hindi:
                    {{ personalDetails.UserDetail[0].Hindi }}, English:
                    {{ personalDetails.UserDetail[0].English }}
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <img
                  style="width: 210px"
                  :src="getImgUrl(personalDetails.Documents[0].PhotoPath)"
                /><br />
                <img
                  style="width: 210px; height: 105px"
                  :src="getImgUrl(personalDetails.Documents[0].SignaturePath)"
                />
                <p>Specimen Signature</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Compassionate Case Details -->
        <div
          class="reg-form-box"
          v-if="personalDetails.UserDetail[0].RegistrationType == 3"
        >
          <h5 class="reg-form-title text-primary">
            Deceased Guard Information
          </h5>
          <div class="reg-form-body">
            <div class="row">
            <div class="col-md-4">Deceased Guard Name</div>
              <div class="col-md-6">
                {{ personalDetails.UserDetail[0].DeceasedName }}
              </div>
              <div class="col-md-4">Deceased Registration Number</div>
              <div class="col-md-6">
                {{ personalDetails.UserDetail[0].DeceasedRegNum }}
              </div>
               <div class="col-md-4">Legal Heir Name(Registration in the board )</div>
              <div class="col-md-6">
                {{ personalDetails.UserDetail[0].HeirName || "" }}
              </div>
              
              <div class="col-md-4">Relation with Deceased Guard</div>
              <div class="col-md-6">
                {{ personalDetails.UserDetail[0].DeceasedRelation }}
              </div>

             
            </div>
          </div>
        </div>

        <!-- Court Order Details -->
        <div
          class="reg-form-box"
          v-if="personalDetails.UserDetail[0].RegistrationType == 4"
        >
          <h5 class="reg-form-title text-primary">Court Order Details</h5>
          <div class="reg-form-body">
            <div class="row">
              <div class="col-md-4">Order Number</div>
              <div class="col-md-8">
                {{ personalDetails.UserDetail[0].OrderNum }}
              </div>

              <div class="col-md-4">Order Date</div>
              <div class="col-md-8">
                {{ personalDetails.UserDetail[0].OrderDate | formatDate }}
              </div>

              <div class="col-md-4">Court Name</div>
              <div class="col-md-8">
                {{ personalDetails.UserDetail[0].CourtName }}
              </div>
            </div>
          </div>
        </div>

        <!-- Religion & Cast -->
        <div class="reg-form-box">
          <h5 class="reg-form-title text-primary">Religion/ Category</h5>
          <div class="reg-form-body">
            <div class="row">
              <div class="col-md-2"><strong>Religion:</strong></div>
              <div class="col-md-2">
                {{ personalDetails.ReligionCaste[0].ReligionName }}
              </div>

              <div class="col-md-2"><strong>Category:</strong></div>
              <div class="col-md-2">
                {{ personalDetails.ReligionCaste[0].CategoryName }}
              </div>

              <div class="col-md-2"><strong>Sub-Caste:</strong></div>
              <div class="col-md-2">
                {{ personalDetails.ReligionCaste[0].SubCaste }}
              </div>
            </div>
          </div>
        </div>

        <!-- Employment Exchange -->
        <div class="reg-form-box" v-if="personalDetails.UserEmpExch">
          <h5 class="reg-form-title text-primary">
            Employment Exchange Details
          </h5>
          <div class="reg-form-body">
            <div class="row">
              <div class="col-md-4">
                Whether registered in Employment Exchange?
              </div>
              <div
                class="col-md-8"
                v-if="personalDetails.UserEmpExch[0].EmploymentName != ''"
              >
                Yes
              </div>
              <div class="col-md-8" v-else>No</div>
            </div>
            <div class="row">
              <div class="col-md-4">Employment Exchange Name</div>
              <div class="col-md-8">
                {{ personalDetails.UserEmpExch[0].EmploymentName }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Employment Exchange Registration No :</div>
              <div class="col-md-8">
                {{ personalDetails.UserEmpExch[0].EmploymentNo }}
              </div>
            </div>
          </div>
        </div>

        <!-- Correspondance Address -->
        <div class="reg-form-box">
          <h5 class="reg-form-title text-primary">
            Correspondence Address Details
          </h5>
          <div class="reg-form-body">
            <div class="row">
              <div class="col-md-2">Address:</div>
              <div class="col-md-10">
                {{ personalDetails.CorrespondanceAddress[0].CoAddress }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">Village:</div>
              <div class="col-md-4">
                {{ personalDetails.CorrespondanceAddress[0].VillageCity }}
              </div>
              <div class="col-md-2">Taluka:</div>
              <div class="col-md-6">
                {{ personalDetails.CorrespondanceAddress[0].TalukaName }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">District:</div>
              <div class="col-md-4">
                {{ personalDetails.CorrespondanceAddress[0].DistrictName }}
              </div>
              <div class="col-md-2">State:</div>
              <div class="col-md-4">
                {{ personalDetails.CorrespondanceAddress[0].StateName }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">Pincode</div>
              <div class="col-md-4">
                {{ personalDetails.CorrespondanceAddress[0].Pincode }}
              </div>
              <div class="col-md-2">Post</div>
              <div class="col-md-4">
                {{ personalDetails.CorrespondanceAddress[0].Post }}
              </div>
            </div>
          </div>
        </div>

        <!-- Permanent Address -->
        <div class="reg-form-box">
          <h5 class="reg-form-title text-primary">Permanent Address Details</h5>
          <div class="reg-form-body">
            <div class="row">
              <div class="col-md-2">Address:</div>
              <div class="col-md-10">
                {{ personalDetails.PermanentAddress[0].PerAddress }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">Village:</div>
              <div class="col-md-4">
                {{ personalDetails.PermanentAddress[0].VillageCity }}
              </div>

              <div class="col-md-2">Taluka:</div>
              <div class="col-md-4">
                {{ personalDetails.PermanentAddress[0].TalukaName }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">District:</div>
              <div class="col-md-4">
                {{ personalDetails.PermanentAddress[0].DistrictName }}
              </div>

              <div class="col-md-2">State:</div>
              <div class="col-md-4">
                {{ personalDetails.PermanentAddress[0].StateName }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">Pincode:</div>
              <div class="col-md-4">
                {{ personalDetails.PermanentAddress[0].Pincode }}
              </div>
              <div class="col-md-2">Post:</div>
              <div class="col-md-4">
                {{ personalDetails.PermanentAddress[0].Post }}
              </div>
            </div>
          </div>
        </div>

        <!-- Emergency Contact -->
        <div class="pagebreak"></div>
        <div class="reg-form-box">
          <h5 class="reg-form-title text-primary">User Emergency Contact</h5>
          <div class="reg-form-body">
            <div class="row">
              <div class="col-md-2">Contact Name:</div>
              <div class="col-md-6">
                {{ personalDetails.EmergencyContact[0].ContactName }}
              </div>
              <div class="col-md-2">Mobile No :</div>
              <div class="col-md-2">
                {{ personalDetails.EmergencyContact[0].MobileNo }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">Address :</div>
              <div class="col-md-8">
                {{ personalDetails.EmergencyContact[0].Address }}
              </div>
            </div>
          </div>
        </div>

        <!-- Physical Details -->
        
        <div class="reg-form-box">
          <h5 class="reg-form-title text-primary">Physical Detail</h5>
          <div class="reg-form-body">
            <table class="table table-sm">
              <tr>
                <th>Identification Mark</th>
                <th>Height(cm)</th>
                <th>Weight(kg)</th>
                <th>Chest Deflated</th>
                <th>Chest Inflated</th>
              </tr>
              <tr>
                <td>
                  {{ personalDetails.PhysicalDetail[0].IdentificationMark }}
                </td>
                <td>
                  {{ personalDetails.PhysicalDetail[0].Height }}
                </td>
                <td>
                  {{ personalDetails.PhysicalDetail[0].Weight }}
                </td>
                <td>
                  {{ personalDetails.PhysicalDetail[0].ChestDeflated }}
                </td>
                <td>
                  {{ personalDetails.PhysicalDetail[0].ChestInflated }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Family Members -->
       
        <div class="reg-form-box">
          <h5 class="reg-form-title text-primary">Family Members</h5>
          <div class="reg-form-body">
            <table class="table table-sm">
              <tr>
                <th>FullName</th>
                <th>Gender</th>
                <th>DOB</th>
                <th>Relation</th>
              </tr>
              <tr
                v-for="member in personalDetails.FamilyDetails"
                :key="member.FullName"
              >
                <td>{{ member.FullName }}</td>
                <td>{{ member.Gender }}</td>
                <td>{{ member.DOB }}</td>
                <td>{{ member.RelationshipName }}</td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Nomination Details -->
        <div class="reg-form-box">
          <h5 class="reg-form-title text-primary">Nominee Detail</h5>
          <div class="reg-form-body">
            <table class="table table-sm">
              <tr>
                <th>FullName</th>
                <th>Gender</th>
                <th>DOB</th>
                <th>Relation</th>
                <th>Details</th>
                <th>Guardian Name</th>
                <th>Guardian Relation</th>
                <th>Guardian Address</th>
              </tr>
              <tr
                v-for="member in personalDetails.Nominee"
                :key="member.NomineeName"
              >
                <td>{{ member.NomineeName }}</td>
                <td>{{ member.Gender }}</td>
                <td>{{ member.DOB }}</td>
                <td>{{ member.RelationshipName }}</td>
                <td>{{ member.RelationDetail }}</td>
                <td>{{ member.GuardianName || "" }}</td>
                <td>{{ member.GuardianRelation || "" }}</td>
                <td>{{ member.GuardianAddress || "" }}</td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Experience Details -->
        <div class="reg-form-box empdet" v-if="personalDetails.Experience">
          <h5 class="reg-form-title text-primary">Employer Details</h5>
          <div class="reg-form-body">
            <div class="row">
              <div class="col-md-4">Working Status:</div>
              <div
                class="col-md-8"
                v-if="(personalDetails.Experience[0].workingStatus = 1)"
              >
                Present
              </div>
              <div class="col-md-8" v-else>Past</div>
            </div>
            <div class="row">
              <div class="col-md-4">Name of Agency</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].AgencyName }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Address of the Agency</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].AgencyAddress }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Agency Contact Number</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].AgencyContactNo }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Pasara License Number</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].PasaraLicense }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Validity Date</div>
              <div class="col-md-8">
                {{
                  personalDetails.Experience[0].PasaraValidityDate | formatDate
                }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Name Of the Principal Employer</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].PrincipalEmployerName }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Principal Employer Address</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].PrincipalEmployerAddress }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Principal Employer Contact Number</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].PrincipalContactNo }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Location Of Work Place</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].WorkingLocation }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Nature of Appointment</div>
              <div
                class="col-md-8"
                v-if="(personalDetails.Experience[0].AppointmentType = 1)"
              >
                Direct Employer
              </div>
              <div class="col-md-8" v-else>Through Agency</div>
            </div>
            <div class="row">
              <div class="col-md-4">PF NO</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].PFNO }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">ESIC NO</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].ESICNO }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Basic Pay</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].BasicPay }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">DA</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].DA }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Total Salary</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].TotalSalary }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Date Of Joining</div>
              <div class="col-md-8">
                {{ personalDetails.Experience[0].StartDate | formatDate }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">Last Date of Working</div>
              <div
                class="col-md-8"
                v-if="(personalDetails.Experience[0].workingStatus = 1)"
              >
                Till Date
              </div>
              <div class="col-md-8" v-else>
                {{ personalDetails.Experience[0].EndDate | formatDate }}
              </div>
            </div>
          </div>
        </div>

        <!-- Educational Qualification -->
        <div class="reg-form-box">
          <h5 class="reg-form-title text-primary">Qualification Details</h5>
          <div class="reg-form-body">
            <table class="table table-sm">
              <tr>
                <th>Qualification Type</th>
                <th>Name of Degree</th>
                <th>State</th>
                <th>Board / University</th>
                <th>Class</th>
                <th>Percentage</th>
                <th>Result</th>
              </tr>
              <tr>
                <td>
                  {{ personalDetails.Qualification[0].QualificationType }}
                </td>
                <td>
                  {{ personalDetails.Qualification[0].DegreeName || "" }}
                </td>
                <td>
                  {{ personalDetails.Qualification[0].StateName }}
                </td>
                <td>
                  {{ personalDetails.Qualification[0].BoardName }}
                </td>
                <td>
                  {{ personalDetails.Qualification[0].Class }}
                </td>
                <td>
                  {{ personalDetails.Qualification[0].Percentage }}
                </td>
                <td>
                  {{ personalDetails.Qualification[0].Result }}
                </td>
              </tr>
            </table>
            <div class="row">
              <div class="col-md-4">Computer Proficiency</div>
              <div class="col-md-8">
                {{ personalDetails.Qualification[0].ComputerProficiency }}
              </div>
            </div>
          </div>
        </div>

        <!-- Documents Uploaded -->
        <div class="reg-form-box">
          <h5 class="reg-form-title text-primary">Documents Uploaded</h5>
          <div class="reg-form-body">
            <table class="table table-sm">
              <tr>
                <th>Document Type</th>
                <th>Details</th>
                <th>Link</th>
                <th v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23">Approve?</th>
              </tr>
              <tr v-if="personalDetails.Documents[0].PhotoPath != ''">
                <td>Photo</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="getImgUrl(personalDetails.Documents[0].PhotoPath)"
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.ApplicantPhotoStatus"
                    :options="approveOptions"
                    v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Photo')"
                  ></b-form-select>
                </td>
              </tr>

              <tr v-if="personalDetails.Documents[0].SignaturePath != ''">
                <td>Signature</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(personalDetails.Documents[0].SignaturePath)
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.ApplicantSignatureStatus"
                    :options="approveOptions"
                  v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Signature')"
                  ></b-form-select>
                </td>
              </tr>

              <tr v-if="personalDetails.Documents[0].AdharCardPath != ''">
                <td>Adhar Card</td>
                <td>{{ personalDetails.UserDetail[0].AdharNo }}</td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(personalDetails.Documents[0].AdharCardPath)
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.AdharCardStatus"
                    :options="approveOptions"
                     v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Adhar Card')"
                  ></b-form-select>
                </td>
              </tr>

              
              <tr v-if="personalDetails.Documents[0].PancardPath != ''">
                <td>Pan Card</td>
                <td>{{ personalDetails.UserDetail[0].PanCardNo }}</td>
                <td>
                  <a
                    target="_blank"
                    :href="getImgUrl(personalDetails.Documents[0].PancardPath)"
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.PanCardStatus"
                    :options="approveOptions"
                     v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Pan Card')"
                  ></b-form-select>
                </td>
              </tr>

              <tr v-if="personalDetails.Documents[0].RationCardPath != ''">
                <td>Ration Card</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(personalDetails.Documents[0].RationCardPath)
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.RationCardStatus"
                    :options="approveOptions"
                     v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Ration Card')"
                  ></b-form-select>
                </td>
              </tr>

              <tr v-if="personalDetails.Documents[0].CasteCertificate != ''">
                <td>Caste Certificate</td>
                <td>
                  {{ personalDetails.ReligionCaste[0].CategoryName }} /
                  {{ personalDetails.ReligionCaste[0].SubCaste }}
                </td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(personalDetails.Documents[0].CasteCertificate)
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.CasteCertificateStatus"
                    :options="approveOptions"
                     v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Caste Certificate')"
                  ></b-form-select>
                </td>
              </tr>

              <tr v-if="personalDetails.Documents[0].EduCertificate != ''">
                <td>Education Certificate</td>
                <td>
                  {{ personalDetails.Qualification[0].QualificationType }} /
                  {{ personalDetails.Qualification[0].Result }} /
                  {{ personalDetails.Qualification[0].Percentage }}%
                </td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(personalDetails.Documents[0].EduCertificate)
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.DegreeCertificateStatus"
                    :options="approveOptions"
                     v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Education Cert.')"
                  ></b-form-select>
                </td>
              </tr>

              <tr v-if="personalDetails.Documents[0].ExServiceManProof != ''">
                <td>ExServiceman Proof</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(personalDetails.Documents[0].ExServiceManProof)
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.ExServiceManCertificateStatus"
                    :options="approveOptions"
                     v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Ex-Serviceman Proof')"
                  ></b-form-select>
                </td>
              </tr>

              <tr
                v-if="personalDetails.Documents[0].ExprienceCertificate != ''"
              >
                <td>Experience Certificate</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(
                        personalDetails.Documents[0].ExprienceCertificate
                      )
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.ExprienceCertificateStatus"
                    :options="approveOptions"
                    v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Experience Letter')"
                  ></b-form-select>
                </td>
              </tr>

              <tr v-if="personalDetails.Documents[0].BirthCertificate != ''">
                <td>Birth Certificate</td>
                <td>{{ personalDetails.UserDetail[0].DOB }}</td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(personalDetails.Documents[0].BirthCertificate)
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.DOBCertificateStatus"
                    :options="approveOptions"
                     v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Birth Certificate')"
                  ></b-form-select>
                </td>
              </tr>

              <tr
                v-if="personalDetails.Documents[0].RecommendationLetter != ''"
              >
                <td>Recommendation Letter</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(
                        personalDetails.Documents[0].RecommendationLetter
                      )
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.RecommendationLetterStatus"
                    :options="approveOptions"
                    v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Recommendation Letter')"
                  ></b-form-select>
                </td>
              </tr>

              <tr v-if="personalDetails.Documents[0].DeceasedID != ''">
                <td>Deceased Guard's ID</td>
                <td>{{ personalDetails.UserDetail[0].DeceasedRegNum }}</td>
                <td>
                  <a
                    target="_blank"
                    :href="getImgUrl(personalDetails.Documents[0].DeceasedID)"
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.DeceasedID"
                    :options="approveOptions"
                   v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                  ></b-form-select>
                </td>
              </tr>

              <tr v-if="personalDetails.Documents[0].DeathCertificate != ''">
                <td>Deceased Guard's Death Certificate</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(personalDetails.Documents[0].DeathCertificate)
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.DeathCertificate"
                    :options="approveOptions"
                      v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Death Certificate')"
                  ></b-form-select>
                </td>
              </tr>

              <tr v-if="personalDetails.Documents[0].LegalHireNOC != ''">
                <td>Legal Hire NOC</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="getImgUrl(personalDetails.Documents[0].LegalHireNOC)"
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.LegalHireNOCStatus"
                    :options="approveOptions"
                    v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Legal Heir NOC')"
                  ></b-form-select>
                </td>
              </tr>

              <tr v-if="personalDetails.Documents[0].FinalDuesLetter != ''">
                <td>Final Dues Letter</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(personalDetails.Documents[0].FinalDuesLetter)
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.FinalDuesLetter"
                    :options="approveOptions"
                     v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Final Dues Letter')"
                  ></b-form-select>
                </td>
              </tr>

              <tr v-if="personalDetails.Documents[0].CourtOrder != ''">
                <td>Court Order Copy</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="getImgUrl(personalDetails.Documents[0].CourtOrder)"
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.CourtOrder"
                    :options="approveOptions"
                     v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Court Order')"
                  ></b-form-select>
                </td>
              </tr>

              <tr v-if="personalDetails.Documents[0].PFDeclaration != ''">
                <td>PF/ESIC Declaration</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(personalDetails.Documents[0].PFDeclaration)
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.PFDeclaration"
                    :options="approveOptions"
                     v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'PF Declaration')"
                  ></b-form-select>
                </td>
              </tr>
               <tr v-if="personalDetails.Documents[0].NameChangeProof != ''">
                <td>Name Change certificate (Gazzete)</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(personalDetails.Documents[0].NameChangeProof)
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.NameChangeProof"
                    :options="approveOptions"
                    v-if="ApplicationStatus == 4 ||ApplicationStatus == 5||ApplicationStatus == 23"
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Name ChangeProof')"
                  ></b-form-select>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Ground and Physical Marks -->

       
        <div class="reg-form-box" v-if="personalDetails.Evaluation">
          <h5 class="reg-form-title text-primary">Ground/Physical Details</h5>
          <div class="reg-form-body">
            <table class="table table-sm">
              <tr>
                <th>Height(cm)</th>
                <th>Weight(kg)</th>
                <th>Chest D (cm)</th>
                <th>Chest I (cm)</th>
                <th>Running Time</th>
                <th>Runnning Marks</th>
                <th>PullUps</th>
                <th>PullUp Marks</th>
                <th>Situps</th>
                <th>Situp Marks</th>
                <th>Ground Marks</th>
              </tr>
              <tr>
                <td>
                  {{ personalDetails.Evaluation[0].Height }}
                </td>
                <td>
                  {{ personalDetails.Evaluation[0].Weight || "" }}
                </td>
                <td>
                  {{ personalDetails.Evaluation[0].ChestDeflated }}
                </td>
                <td>
                  {{ personalDetails.Evaluation[0].ChestInflated }}
                </td>
                <td>
                  {{ personalDetails.Evaluation[0].RunningTime }}
                </td>
                <td>
                  {{ personalDetails.Evaluation[0].RunningMark }}
                </td>
                <td>
                  {{ personalDetails.Evaluation[0].PullUps }}
                </td>
                <td>
                  {{ personalDetails.Evaluation[0].PullupsMark }}
                </td>
                <td>
                  {{ personalDetails.Evaluation[0].SitUps }}
                </td>
                <td>
                  {{ personalDetails.Evaluation[0].SitupsMark }}
                </td>
                <td>
                  {{ personalDetails.Evaluation[0].GroundMarks }}
                </td>
              </tr>
            </table>
            <div class="row">
              <div class="col-md-4">
                <strong>Education Marks : </strong>
                {{ personalDetails.Evaluation[0].EducationalMark }}
              </div>
              <div class="col-md-4">
                <strong>Total Marks : </strong>
                {{ personalDetails.Evaluation[0].TotalMarks }}
              </div>
              <div class="col-md-4">
                <strong>Ground Result : </strong>
                {{ personalDetails.Evaluation[0].ResultStatus }}
              </div>
              <div
                class="col-md-12 mt-3"
                v-if="
                  personalDetails.Evaluation[0].TotalMarks ==
                  'Conditional Exempted'
                "
                style="text-align: center; color:red"
              >
                THIS APPLICATION IS APPROVED ON CONDITIONAL BASIS - CANDIDATE'S GROUND TEST IS PENDING
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- tushar start -->
      <div class=" hide-print reg-form-box" v-if="ApplicationStatus == 15||
                      ApplicationStatus == 16||
                      ApplicationStatus ==17||
                      ApplicationStatus ==18||
                      ApplicationStatus ==19||
                      ApplicationStatus ==20||
                      ApplicationStatus ==21||
                      ApplicationStatus ==24||
                      ApplicationStatus ==26||
                      ApplicationStatus ==27||
                      ApplicationStatus ==30||
                      ApplicationStatus ==34||
                      ApplicationStatus ==37||
                      ApplicationStatus ==39 
          
          
          ">
                  <h5 class="reg-form-title text-primary">Medical & Character Certificate</h5>
          <th ></th>
          <div class="reg-form-body">
            <table class="table table-sm">
              
                <tr>
                <th>Document Type</th>
                <th>Details</th>
                <th>Link</th>
                <th v-if="form.ApplicationStatus == 15||
                ApplicationStatus == 30||
                ApplicationStatus == 34 "
              
                      >Approve?</th>
               
                      
              </tr>
              <tr v-if="personalDetails.Documents[0].PoliceReceiptPath != ''">
                <td>Police Receipt</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="getImgUrl(personalDetails.Documents[0].PoliceReceipt)"
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.ApplicantPoliceReceiptStatus"
                    :options="approveOptions"
                    v-if="ApplicationStatus == 15|| 
                   ApplicationStatus == 30||
                   ApplicationStatus == 34 "
                   
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Police Receipt')"
                  ></b-form-select>
                </td>
              </tr>
              <tr v-if="personalDetails.Documents[0].PoliceVerificationPath != ''">
                <td>Police Certificate</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(personalDetails.Documents[0].PoliceVerification)
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.ApplicantPoliceVerificationStatus"
                    :options="approveOptions"
                    v-if="ApplicationStatus == 15|| 
                   ApplicationStatus == 30||
                   ApplicationStatus == 34 
                  
                   "
                   
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Police Certificate')"
                  ></b-form-select>
                </td>
              </tr>
              <tr v-if="personalDetails.Documents[0].MedicalCertificatePath != ''">
                <td>Medical Certificate</td>
                <td></td>
                <td>
                  <a
                    target="_blank"
                    :href="
                      getImgUrl(personalDetails.Documents[0].MedicalCertificate)
                    "
                    >View</a
                  >
                </td>
                <td>
                  <b-form-select
                    v-model="approvalStatus.ApplicantMedicalCertificateStatus"
                    :options="approveOptions"
                    v-if="ApplicationStatus == 15|| 
                   ApplicationStatus == 30||
                   ApplicationStatus == 34 
                    "
                    
                    size="sm"
                    style="width: 100px"
                    @change.native="approveDoc($event, 'Medical Certificate')"
                  ></b-form-select>
                </td>
              </tr>
              </table>
              
          </div>
          </div>
          <!-- tushar end  -->

      <!-- Approval /Rejection -->
      <div class="hide-print reg-form-box">
        <b-spinner
          variant="primary"
          label="Loading..."
          v-if="isLoading"
        ></b-spinner>
        <b-container class="bv-example-row">
          <b-row>
         
            <b-col cols="10" offset="1">
              
              <h5 class="reg-form-title text-primary">Approval History</h5>
              
              <b-table
                show-empty
                selectable
                select-mode="multi"
                head-variant="dark"
                empty-text="No Applications available."
                stacked="md"
                bordered
                striped
                :items="approvalRemarks"
                :fields="approvalFields"
                variant="dark"
                style="font-size: 12px"
              ></b-table>
            </b-col>
          </b-row>
            
        </b-container>
      </div>
    </div>

    <div class="reg-form-box">
      <Spinner size="32" message="Loading..." v-if="isLoading"></Spinner>
      <b-alert
        :show="showDismissibleAlert"
        dismissible
        @dismissed="showDismissibleAlert = false"
        :variant="variant"
        >{{ alertText }}</b-alert
      >
      <b-form @submit="onSubmit" class="hide-print" v-if="showApproval">
        <div class="form-row">
          <div class="form-group col-md-1"></div>
          <div class="form-group col-md-4">
            <b-form-group
              id="fieldset-horizontal"
              label-cols-sm="4"
              label-cols-lg="4"
              label="Approve Application"
              label-for="ipDistrict"
              label-size="sm"
            >
              <b-select
                id="ipDistrict"
                :options="ApprovalStatus"
                value-field="StatusID"
                text-field="Status"
                required
                size="sm"
                @change.native="checkReject"
                v-model="form.ApplicationStatus"
                class="form-control"
              ></b-select>
            </b-form-group>
          </div>
          <div class="form-group col-md-1"></div>
          <div class="form-group col-md-4">
            <b-form-group
              id="fieldset-horizontal2"
              label-cols-sm="4"
              label-cols-lg="4"
              label="Reject Reason"
              label-for="ipReason"
              label-size="sm"
            >
              <b-select
                id="ipReason"
                :options="RejectReasons"
                value-field="RejectionReasonId"
                text-field="RejectionReason"
                required
                size="sm"
                :disabled="!isReject"
                v-model="form.RejectReason"
                class="form-control"
              ></b-select>
            </b-form-group>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-1"></div>
          <div class="form-group col-md-9" style="text-align: left">
            <b-form-group
              id="fieldset-horizontal"
              label-cols-sm="4"
              label-cols-md="1"
              label="Remarks"
              label-for="ipRemarks"
              label-size="sm"
            >
              <b-form-textarea
                id="ipRemarks"
                v-model="form.Remarks"
                
                size="sm"
                rows="2,"
                max-rows="4"
                placeholder="Remarks"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <b-col style="text-align: center" class="mb-3">
          <b-button
            class="hide-print"
            type="submit"
            variant="primary"
            v-if="showButton"
            >Submit</b-button
          >
        </b-col>
      </b-form>
      <div class="text-center">
        <b-button
          class="hide-print"
          @click.prevent="goback"
          variant="secondary"
          size="small"
          >BACK</b-button
        >
      </div>
    </div>
    <b-modal ref="bformModal" size="xl" hide-footer title="B FORM Receipt">
      <user-b-form
        :appid="form.ApplicationID"
        :appuserid="personalDetails.UserID"
      ></user-b-form>
    </b-modal>
   
     <b-modal id="confiramation_model" title="Confirmation" hide-footer>
        <p class="my-4">Are You Sure, you want to Unlock Profile?</p>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="unlock1">
            OK
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="cancellock">
            Cancel
          </button>
        </div>
      </b-modal>
      
  </b-container>
</template>

<script>
import apis from "@/apis";
import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
  
import vueHtmlToPaper from "vue-html-to-paper";
import DatePicker from "vue2-datepicker";
import Formatter from "@/Formatter.js";
import UserBForm from "@/components/UserBform.vue";

export default {
  components: { DatePicker, UserBForm, Loading},
  props: ["ApplicationID", "ApplicationStatus",],
  data() {
    return {
      visible: true,
      showButton: true,
      showApproval: false,
      isLoading: false,
      fullPage: false,
      isReject: false,
      required: false,
      approveOptions: [
        { value: null, text: "Pending" },
        { value: 1, text: "Approved" },
        { value: 0, text: "Rejected" },
      ],
      Caste: [],
      CasteID: "",
      PastLOI: false,
      approvalStatus: {
        UserID: "",
        ApplicantPhotoStatus: null,
        ApplicantSignatureStatus: null,
        AdharCardStatus: null,
        PanCardStatus: null,
        RationCardStatus: null,
        CasteCertificateStatus: null,
        DegreeCertificateStatus: null,
        ExServiceManCertificateStatus: null,
        ExprienceCertificateStatus: null,
        DOBCertificateStatus: null,
        PoliceVerificationStatus: null,
        MedicalCertificateStatus: null,
        RecommendationLetterStatus: null,
        LegalHireNOCStatus: null,
        FinalDuesLetter: null,
        CourtOrder: null,
        DeceasedID: null,
        DeathCertificate: null,
        PFDeclaration: null,
        PoliceReceipt: null,
        NameChangeProof: null,
      },
      personalDetails: {
        UserDetail: [{RegistrationType: 2}],
        ReligionCaste:[{ReligionName:""}],
      },
      docObj: {},
      docTbl: [],
      form: {
        ApplicationStatus: 1,
        RejectReason: 0,
        Remarks: "",
        UserID: "",
        ApplicationID: this.ApplicationID,
      },
      RoleID: 0,
      ApprovalStatus: [
        //{ StatusID: 1, Status: "Pending" },
        { StatusID: 2, Status: "Verified" },
        { StatusID: 3, Status: "Hold" },
        { StatusID: 19, Status: "Rejected" },
        //{ StatusID: 15, Status: "Scheduled" }
      ],
      approvalRemarks: [],
      approvalFields: [
        "ApplicationStatus",
        "RejectionReason",
        "RejectRemarks",
        {
          key: "LogDate",
          label: "Approve/Reject Date",
          formatter: "convertDateFormat",
        },
        "ApproverName",
      ],
      RejectReasons: this.$store.getters.getRejectReason,
      alertText: "",
      variant: "success",
      isLoading: false,
      showDismissibleAlert: false,
      showDismissibleAlertUnlock: false,
      isLoadingUnlock: false,
      alertTextUnlock: "",
      variantUnlock: "success",
      uForm: {
        ApplicationID: this.ApplicationID,
        UserID: this.$store.getters.loggedUser.UserId,
        Remarks: "",
      },
    };
  },
  computed: {
    validateAadhar() {
      if (this.personalDetails.AdharNo == null) {
        return false;
      }

      if (this.personalDetails.AdharNo.length == 0) {
        return false;
      } else if (this.personalDetails.AdharNo != null) {
        var adharformat = /^\d{12}$/;
        if (this.personalDetails.AdharNo.match(adharformat)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    validatePan() {
      if (this.personalDetails.PanCardNo == null) {
        return false;
      }

      if (this.personalDetails.PanCardNo.length == 0) {
        return false;
      } else if (this.personalDetails.PanCardNo != null) {
        var panformat = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        if (this.personalDetails.PanCardNo.match(panformat)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    validateAge() {
      // console.log('1');
      //let age = this.calculateAge(this.form.DOB);

      let age = this.calculateAge(this.DOB);
      this.PersonalDetails.Age = age;
      if (age < 18 || age >= 50) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    approveDoc(evt, docType) {
      if (evt.target.value == 0) {
        if (this.form.Remarks == "" ) {
          this.form.Remarks = "Error in Documents: " + docType;
        } else {
          this.form.Remarks += ", " + docType;
        }
      } else {
        if (this.form.Remarks == "Error in Documents: " + docType) {
          this.form.Remarks = "";
        } else {
          this.form.Remarks = this.form.Remarks.replace(", " + docType, "");
          this.form.Remarks = this.form.Remarks.replace(
            ": " + docType + ",",
            ":"
          );
        }
      }
    },
    checkReject(evt) {
      
      let id = parseInt(evt.target.value);
      let appStatus = _.filter(this.ApprovalStatus, { StatusID: id });
      if (appStatus) {
        if (appStatus.length > 0) {
          this.isReject = appStatus[0].ISReject;
          if (!this.isReject) {
            this.form.RejectReason = 0;
          }
        }
      }
    },
    cancellock(){
         this.$root.$emit("bv::hide::modal", "confiramation_model");
    },
    async unlock1(){
        this.$root.$emit("bv::hide::modal", "confiramation_model");
          let promise = apis.UnlockProfile(this.uForm);

          promise
            .then((response) => {
              if (response.status == 200) {
                this.$dialog.alert(
                  "Application Unlocked for Changes till tomorrow 9:00 PM"
                );
              } else {
                this.$dialog.alert(response.body);
              }
              this.isLoadingUnlock = false;
            })
            .catch((err) => {
              this.alertTextUnlock = err.message;
              this.showDismissibleAlertUnlock = true;
              this.variantUnlock = "danger";
              this.isLoadingUnlock = false;
            });
    },
    previous(){
      this.unlock();
    },
    async unlock() {
      this.isLoadingUnlock = true;
   
      try {
        let dialog = await this.$dialog.prompt(
          {
            title: "Unlock Application",
            body: "Reason for Unlocking the application:",
          },
          {
            promptHelp: 'Type in the box below and click "[+:okText]"',
            okText: "Unlock Profile",
            cancelText: "Cancel",
            loader: false,
          }
        );

        if (dialog.data) {
          this.uForm.Remarks = dialog.data;
          this.$root.$emit("bv::show::modal", "confiramation_model");
        } else {
         this.isLoadingUnlock = false;
         this.$dialog.alert({
          title: "Alert",
          body: "Please Mention Reason.",
          confirmText: "Continue", // Set the label for the "Continue" button
          }).then(() => {
          // This function will be called when the user clicks "Continue"
          this.previous();
        });
          return false;
          
        }
      } catch (err) {
        this.isLoadingUnlock = false;
      }
    },
    goback() {
      //this.$router.back();
      this.$router.push({
        name: "Applications",
        params: {
          userDistrictID: this.$store.getters.getUserDistrict,
          userApprovalStatus: this.$store.getters.getAppStatus,
          userLoanType: 1,
        },
      });
    },
    calculateAge(birthDate) {
      birthDate = new Date(birthDate);
      let incrementDate = new Date(birthDate);
      // console.log(incrementDate);
      let today = new Date();
      let n = 0;
      while (incrementDate < today) {
        n++;
        incrementDate.setYear(incrementDate.getFullYear() + 1);
      }

      return n - 1;
    },
    convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.form.ApplicationStatus == this.ApplicationStatus) {
        return;
      }
      if (this.form.Remarks=="" && this.isReject) {
        this.$dialog.alert("Please Enter Remarks");
        return ;
      }
      
      this.approvalStatus.UserID = this.form.UserID;
      let promise = apis.UpdateApplicationStatus(this.form);
      
      if (this.form.ApplicationStatus==4 || this.form.ApplicationStatus==15 || this.form.ApplicationStatus==23 || this.form.ApplicationStatus==24 || this.form.ApplicationStatus==30) {
        apis.updateDocStatus(this.approvalStatus); //we are not checking the promise status.
      }
      promise
        .then((response) => {
          if (response.status == 200) {
            //Check the profile completion status
            this.alertText = "Application Status changed Successfully";
            this.variant = "success";
            this.showDismissibleAlert = true;
            this.isLoading = false;
            this.showButton = false;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
        });
    },
    print() {
      window.print();
      //this.$htmlToPaper("printme");
    },
    edit() {
      this.$router.push("/LoginProfile");
    },
    getImgUrl(pic) {
      if (pic) {
        return (
          "https://s3.ap-south-1.amazonaws.com/sgbimages/" +
          this.personalDetails.UserID.toLowerCase() +
          "/" +
          pic
        );
      }
    },
    EditField(fld) {
      if (this.editList[fld]) {
        switch (fld) {
          case "ePan":
            if (!this.validatePan) {
              this.alertText = "Please verify the PAN";
              this.variant = "warning";
              this.showDismissibleAlert = true;
              this.isLoading = false;
              return;
            }
            this.updateForm.Pan = this.personalDetails.PanCardNo;
            break;
          case "eAadhar":
            if (!this.validateAadhar) {
              this.alertText = "Please verify the Aadhar number";
              this.variant = "warning";
              this.showDismissibleAlert = true;
              this.isLoading = false;
              return;
            }
            this.updateForm.Aadhar = this.personalDetails.AdharNo;
            break;
          case "eRation":
            this.updateForm.RationCard = this.personalDetails.RationCardNo;
            break;
          case "eDOB":
            if (!this.validateAge) {
              this.alertText = "Age Criteria not fulfilled";
              this.variant = "warning";
              this.showDismissibleAlert = true;
              this.isLoading = false;
              return;
            }
            this.updateForm.DOB = Formatter.convertDateFormatYMD(this.DOB);
            this.updateForm.Age = this.age;
            break;
          case "eCaste":
            let casteName = _.filter(this.Caste, { CasteID: this.CasteID });
            this.personalDetails.CasteName = casteName[0].CasteName;
            this.updateForm.CasteID = casteName[0].CasteName;
            break;
        }
        this.updateForm.UserID = this.UserID;
        this.updateForm.LoanApplicationID = this.form.ApplicationID;
        this.updateForm.CreatedUser = this.$store.getters.loggedUser.UserID;

        let promiseA = apis.saveDMChanges(this.updateForm);

        promiseA
          .then((response) => {
            if (response.status == 200) {
              this.alertText = "Data Saved Successfully";
              this.variant = "success";
              this.showDismissibleAlert = true;
            } else {
              this.alertText = "Error while Updating Data, " + response.body;

              this.variant = "warning";
              this.showDismissibleAlert = true;
            }
          })
          .catch((err) => {
            this.alertText = "Error while Updating Data, " + err.message;
            this.showDismissibleAlert = true;
            this.variant = "danger";
          });
      } else {
        switch (fld) {
          case "eCaste":
            let casteid = _.filter(this.Caste, {
              CasteName: this.personalDetails.CasteName,
            });
            this.CasteID = casteid[0].CasteID;
            break;
        }
      }
      this.editList[fld] = !this.editList[fld];
    },
    CloseField(fld) {
      this.editList[fld] = !this.editList[fld];
    },
    Docket_no(item)
    {
      //var temp=item.match(/.{1,3}/g);

      const [first, ...rest] = item.split('MUM');
      rest[0] = Number(rest[0]).toString();
      return rest[0];
    },
    printBform() {
      this.PastLOI = false;
      this.$refs["bformModal"].show();
      // this.$router.push({
      //   name: "LoanLOIPrint",
      //   params: { ApplicationID: this.form.ApplicationID, Loantype: 1, UserID: this.UserID }
      // });
    },
  },

  mounted() {
    var userinfo = this.$store.getters.loggedUser;
    this.RoleID = userinfo.RoleID;
    this.form.ApplicationStatus = this.ApplicationStatus;
    this.showApproval = false;
  this.isLoading=true;
    let promise = apis.ReadApplicationStatusByRoleStatus({
      Role: userinfo.RoleID,
      Status: this.ApplicationStatus,
    });

    promise
      .then((response) => {
         this.isLoading=false;
        if (response.status == 200) {
          this.ApprovalStatus = response.body;
          if (this.ApprovalStatus.length > 0) {
            this.showApproval = true;
          }
        } else {
          this.ApprovalStatus = [];
        }
      })
      .catch((err) => {
        this.ApprovalStatus = [];
        this.alertText = "Error in Loading Application Staus Master";
        this.variant = "danger";
        this.showDismissibleAlert = true;
      });

    this.form.UserID = userinfo.UserId;
     this.isLoading=true;
    let promiseU = apis.readUserDetail({ BoardID: this.ApplicationID }); //Personal Details

    promiseU
      .then((response) => {
         this.isLoading=false;
        if (response.status == 200) {
          //console.log(response.body);
          this.personalDetails = response.body[0];
          this.isLoading = false;
        } else {
          this.alertText = response.body;

          this.variant = "warning";
          this.showDismissibleAlert = true;
        }
      })
      .catch((err) => {
        this.alertText = err.message;
        this.showDismissibleAlert = true;
        this.variant = "danger";
      });
 this.isLoading=true;
    let promiseSL = apis.ReadStatusLog({ ID: this.ApplicationID });

    promiseSL
      .then((response) => {
         this.isLoading=false;
        if (response.status == 200) {
          this.approvalRemarks = response.body;
        }
      })
      .catch((err) => {
        this.alertText = err.message;
        this.showDismissibleAlert = true;
        this.variant = "danger";
      });
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  /* border-collapse: collapse; */
  width: 96%;
  margin-left: 2%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 6px;
}
.newfont {
  font-size: 0.875rem;
}
</style>